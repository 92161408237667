// App.js

import React, { useState } from 'react';
import './App.css';

const AdviceCard = ({ isUser, text }) => (
  <div className={`advice-card ${isUser ? 'user' : 'other'}`}>
    <div className="avatar text-red-50">{isUser ? 'You' : 'AdviceBot'}</div>
    <div className="text">{text}</div>
  </div>
);

const App = () => {
  const [inputText, setInputText] = useState('');
  const [adviceList, setAdviceList] = useState([]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleAdviceSubmit = () => {
    if (inputText.trim() !== '') {
      setAdviceList([...adviceList, { text: inputText, isUser: true }]);
      // save to database
      setInputText('');
    }
  };

  return (
    <div className="app flex flex-col bg-black">
      <div>
        <img src="/favicon.ico" alt="logo" className="h-20 w-20 mx-auto" />
        <h1 className='text-3xl font-bold font-mono'>Random Advice</h1>
      </div>
      <p className='text-gray-600 font-mono text-center p-4 text-xl'>Give and receive random advice from strangers around the world!</p>
      <div className="chat-container">
        <div className="advice-list">
          {adviceList.map((advice, index) => (
            <AdviceCard key={index} isUser={advice.isUser} text={advice.text} />
          ))}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Type your advice..."
            value={inputText}
            onChange={handleInputChange}
          />
          <button onClick={handleAdviceSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default App;
